/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 28, 2021 */



@font-face {
    font-family: 'reeyregular';
    src: url('reey-regular-webfont.woff2') format('woff2'),
         url('reey-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}